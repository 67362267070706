import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_BOOK_PARAMETERS_PREC, ReduxState } from '../../types';
import { symbolToName } from '../../utils/symbolToName';
import './Toolbar.scss';
import { changeDisplayParameter, changeParameter } from '../../redux/actions';
import { OrderBookButton } from './Button';

/**
 * Toolbar for order book containing title, symbol and precision
 */
function orderBookToolbar() {
  const parameters = useSelector((state: ReduxState) => {
    return state.parameters;
  });

  const displayParameters = useSelector((state: ReduxState) => {
    return state.displayParameters;
  });

  const dispatch = useDispatch();

  return (
    <div className="toolbar">
      <div className={'title'}>
        <span>ORDER BOOK </span>
        <span className={'detail'}>{symbolToName(parameters.symbol)}</span>
      </div>

      <div className={'toolset'}>
        <OrderBookButton
          disabled={parameters.prec === ORDER_BOOK_PARAMETERS_PREC.P4}
          ariaLabel={'Decrease price precision'}
          onClick={() => {
            dispatch(changeParameter('prec', 1));
          }}
        >
          -.0
        </OrderBookButton>

        <OrderBookButton
          disabled={parameters.prec === ORDER_BOOK_PARAMETERS_PREC.P0}
          ariaLabel={'Increase price precision'}
          onClick={() => {
            dispatch(changeParameter('prec', -1));
          }}
        >
          +.0
        </OrderBookButton>

        <OrderBookButton
          ariaLabel={'Zoom out visualization'}
          disabled={displayParameters.zoom <= 0.1}
          onClick={() => {
            dispatch(changeDisplayParameter('zoom', -0.1));
          }}
        >
          <FontAwesomeIcon icon={faSearchMinus} />
        </OrderBookButton>

        <OrderBookButton
          ariaLabel={'Zoom in visualization'}
          disabled={displayParameters.zoom >= 1.0}
          onClick={() => {
            dispatch(changeDisplayParameter('zoom', 0.1));
          }}
        >
          <FontAwesomeIcon icon={faSearchPlus} />
        </OrderBookButton>
      </div>
    </div>
  );
}

export default orderBookToolbar;

export const OrderBookToolbar = orderBookToolbar;
