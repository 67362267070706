import React from 'react';
import { OrderBookHeaderProps } from '../../types';
import './Header.scss';

/**
 * Displays header (aka TH) for order book
 * @param params
 */
function orderBookHeader(params: OrderBookHeaderProps) {
  return (
    <div className={'header row'}>
      {params.header.map((field) => (
        <div key={field.key} className={field.key}>
          {field.name}
        </div>
      ))}
    </div>
  );
}

export default orderBookHeader;
export const OrderBookHeader = orderBookHeader;
