import { UPDATE_ORDER_BOOK } from '../const';
import { parseResponse } from '../../data/parseResponse';
import sortedHashMap from '../../utils/sortedHashMap';

const initialValue = {
  isFirst: true,
  bids: {
    generation: 0,
    hashMap: sortedHashMap(),
    totalSum: 0,
    modified: false,
  },
  asks: {
    generation: 0,
    hashMap: sortedHashMap(),
    totalSum: 0,
    modified: false,
  },
};

/**
 * Reducer for order book. Take actions from saga and updates the state
 * @param state
 * @param action
 */
const orderBookReducer = (state: any = initialValue, action: any) => {
  switch (action.type) {
    case UPDATE_ORDER_BOOK:
      try {
        const newState = parseResponse({
          orderBook: state,
          newData: action.data,
        });

        if (!newState) {
          return state;
        }

        return {
          ...newState,
        };
      } catch (e) {
        console.error('orderBookReducer', e);
        return state;
      }
    default:
      return state;
  }
};

export default orderBookReducer;
