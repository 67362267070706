import { CHANGE_DISPLAY_PARAMETER } from '../const';
import {
  OrderBookDisplayParameters,
} from '../../types';

const initialValue = {
  zoom: 1,
};

/**
 * Change the display parameters
 * @param state
 * @param action
 */
const displayParametersReducer = (state: any = initialValue, action: any) => {
  switch (action.type) {
    case CHANGE_DISPLAY_PARAMETER: {
      const newParams: OrderBookDisplayParameters = {
        ...state,
      };
      newParams.zoom += action.value;

      return newParams;
    }
    default:
      return state;
  }
};

export default displayParametersReducer;
