import { PriceProp } from '../types';

/**
 * Changes the color of trend lines
 * @param side
 * @param metric
 * @return string decreasing|increasing
 */
export const trendToClass = (side: string, metric: PriceProp) => {
  if (side === 'bids') {
    return 'decreasing';
  }
  return 'increasing';
};
