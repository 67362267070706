import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  bitfinexConnect,
  changeDisplayParameter,
  changeParameter,
} from '../../redux/actions';
import orderBookSide from './Side';
import {
  ORDER_BOOK_PARAMETERS_FREQ,
  ORDER_BOOK_STATUS_MODES,
  ReduxState,
} from '../../types';
import './StatusBar.scss';
import { OrderBookButton } from './Button';

/**
 * Displays status bar for order book containing connection status and throttling mode
 */
function orderBookStatusBar() {
  const status = useSelector((state: ReduxState) => {
    return state.status;
  });
  const parameters = useSelector((state: ReduxState) => {
    return state.parameters;
  });
  const dispatch = useDispatch();

  const isRealTime = parameters.freq === ORDER_BOOK_PARAMETERS_FREQ.F0;

  return (
    <div className="statusbar">
      <div className={'status-indicator'}>
        <span
          className={status.connected ? 'connected' : 'disconnected'}
        ></span>
      </div>

      <OrderBookButton
        disabled={false}
        onClick={() => {
          dispatch(
            changeParameter(
              'freq',
              isRealTime
                ? ORDER_BOOK_PARAMETERS_FREQ.F2
                : ORDER_BOOK_PARAMETERS_FREQ.F0
            )
          );
        }}
        ariaLabel={'Websocket connection throttling'}
      >
        {isRealTime ? 'REAL-TIME' : 'THROTTLED F2'}
      </OrderBookButton>
    </div>
  );
}

export default orderBookStatusBar;
export const OrderBookStatusBar = orderBookStatusBar;
