import { take, call, put, select, takeEvery, cancel } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  bitfinexConnected,
  bitfinexDisconnected,
  updateOrderBook,
} from './actions';
import {
  BITFINEX_CONNECT,
  BITFINEX_CONNECTED,
  BITFINEX_DISCONNECT,
  BITFINEX_DISCONNECTED,
  CHANGE_PARAMETER,
  UPDATE_ORDER_BOOK,
} from './const';
import {
  BitfinexInfoResponse,
  BitfinexSubResponse,
  OrderBookParameters,
  ReduxSagasAction,
  ReduxState,
} from '../types';
import { bufferInterval } from '../const';

// Universal object for currently used socket;
let mainSocket: WebSocket | null = null;

// buffer used to store the data before it is sent to the reducer
let currentBufferInterval: ReturnType<typeof setInterval> | null = null;

/**
 * Returns the parameters from the state
 * @param state
 */
const getParameters = (state: ReduxState) => state.parameters;

/**
 * Main listener, takes actions and establishes the connection
 */
export default function* rootSaga() {
  const { payload } = yield takeEvery(
    BITFINEX_CONNECT,
    handleWebSocketConnection
  );
  const { payloadFromParameterUpdate } = yield takeEvery(
    CHANGE_PARAMETER,
    handleWebSocketConnection
  );
  yield take(BITFINEX_DISCONNECT);
  yield cancel(payload);
  yield cancel(payloadFromParameterUpdate);

  if (currentBufferInterval) {
    clearInterval(currentBufferInterval);
    currentBufferInterval = null;
  }
}

/**
 * Establishes the channel for the websocket
 * Sends the data to the reducer
 */
const handleWebSocketConnection = function* () {
  if (mainSocket) {
    disconnect();
  }
  mainSocket = new WebSocket('wss://api-pub.bitfinex.com/ws/2');

  const parameters: OrderBookParameters = yield select(getParameters);

  const channel: ReturnType<typeof eventChannel> = yield call(
    createWebSocketChannel(parameters),
    mainSocket
  );

  let i = 0;
  try {
    while (true) {
      const data: ReduxSagasAction = yield take(channel);

      switch (data && data.type) {
        case BITFINEX_DISCONNECTED:
          yield put(bitfinexDisconnected());
          break;
        case BITFINEX_CONNECTED:
          yield put(bitfinexConnected());
          break;
        default:
          yield put(updateOrderBook(data));
      }
      i++;
    }
  } finally {
    console.info('OrderBook: WebSocket disconnected');
  }
};

/**
 * Creates the channel for the websocket, maintains the buffer and connection.
 * @param parameters
 */
const createWebSocketChannel =
  (parameters: OrderBookParameters) => (socket: WebSocket) => {
    return eventChannel((emit) => {
      let currentBuffer:
        | Array<
            | BitfinexInfoResponse
            | BitfinexSubResponse
            | Array<number | Array<number>>
          >
        | Array<number | Array<Array<number>>> = [];

      if (currentBufferInterval) {
        clearInterval(currentBufferInterval);
        currentBufferInterval = null;
      }
      currentBufferInterval = setInterval(() => {
        if (currentBuffer.length > 0) {
          emit({
            type: UPDATE_ORDER_BOOK,
            data: currentBuffer,
          });
          currentBuffer = [];
        }
      }, bufferInterval);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        currentBuffer.push(data);
      };

      socket.onopen = () => {
        emit({ type: BITFINEX_CONNECTED });
        if (mainSocket) {
          mainSocket.send(
            JSON.stringify({
              ...parameters,
              event: 'subscribe',
            })
          );
        }
      };

      socket.onclose = () => {
        emit({ type: BITFINEX_DISCONNECTED });
      };

      return () => {
        disconnect();
      };
    });
  };

/**
 * Disconnects the socket
 */
const disconnect = () => {
  if (mainSocket) {
    mainSocket.close();
  }
};
