import React from 'react';
import { OrderBookButtonProps } from '../../types';
import './Bars.scss';
import './Button.scss';

/**
 * Button to change order book parameters
 * @param params
 */
function orderBookButton(params: OrderBookButtonProps) {
  return (
    <button
      disabled={params.disabled}
      aria-label={params.ariaLabel}
      type="button"
      onClick={!params.disabled ? params.onClick : undefined}
    >
      {params.children}
    </button>
  );
}

export default orderBookButton;
export const OrderBookButton = orderBookButton;
