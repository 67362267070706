import { CHANGE_PARAMETER } from '../const';

import {
  ORDER_BOOK_PARAMETER_CHANNEL,
  ORDER_BOOK_PARAMETER_SYMBOL,
  ORDER_BOOK_PARAMETERS_FREQ,
  ORDER_BOOK_PARAMETERS_PREC,
  OrderBookParametersChannel,
  OrderBookParametersFreq,
  OrderBookParametersPrec,
  OrderBookParametersSymbol,
} from '../../types';

const initialValue = {
  symbol: ORDER_BOOK_PARAMETER_SYMBOL.tBTCUSD as OrderBookParametersSymbol,
  channel: ORDER_BOOK_PARAMETER_CHANNEL.book as OrderBookParametersChannel,
  len: 25,
  freq: ORDER_BOOK_PARAMETERS_FREQ.F0 as OrderBookParametersFreq,
  prec: ORDER_BOOK_PARAMETERS_PREC.P1 as OrderBookParametersPrec,
};

/**
 * Reducer for changing the parameters.
 * @param state
 * @param action
 */
const parametersReducer = (state: any = initialValue, action: any) => {
  switch (action.type) {
    case CHANGE_PARAMETER: {
      const newParams = { ...state };

      switch (action.parameter) {
        case 'prec':
          {
            const newPrecision =
              Number(newParams.prec.substring(1)) + action.value;

            if (newPrecision >= 0 && newPrecision < 4) {
              newParams.prec = ('P' + newPrecision) as OrderBookParametersPrec;
            } else {
              console.warn(
                'OrderBook Settings: Bad precision given',
                newPrecision
              );
            }
          }
          break;

        case 'freq':
          newParams.freq = action.value as OrderBookParametersFreq;
          break;
      }

      return newParams;
    }
    default:
      return state;
  }
};

export default parametersReducer;
