import React, { useEffect, useRef, useState } from 'react';
import { OrderBookRowProps } from '../../types';
import {
  OrderBookSideAlert,
  OrderBookSideAmount,
  OrderBookSideCount,
  OrderBookSidePlaceholder,
} from './index';
import OrderBookSideTotal from './Side/Total';
import OrderBookSidePrice from './Side/Price';
import './Row.scss';
import { trendToClass } from '../../utils/trendToClass';

/**
 * Displays row (aka TR) for order book
 * @param params
 */
function orderBookRow(params: OrderBookRowProps) {
  const activeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [hash, setHash] = useState(params.metric.amount);
  const [active, setActive] = useState(0);
  const [ending, setEnding] = useState<boolean>(false);

  useEffect(() => {
    if (params.metric.amount != hash) {
      setHash(params.metric.amount);
      setActive(1);
      setEnding(false);
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current);
        activeTimeout.current = null;
      }
      activeTimeout.current = setTimeout(() => {
        setActive(0);
        setEnding(true);
      }, 200);
    }
  }, [params.metric]);

  return (
    <div
      key={'index' + params.index}
      className={
        'row ' +
        (active
          ? 'row-active ' + trendToClass(params.side, params.metric)
          : '') +
        (ending ? 'row-active-ending ' : '')
      }
    >
      {params.header.map((field) => {
        let FieldDefinition: any;
        switch (field.key) {
          case 'placeholder':
            FieldDefinition = OrderBookSidePlaceholder;
            break;
          case 'alert':
            FieldDefinition = OrderBookSideAlert;
            break;
          case 'count':
            FieldDefinition = OrderBookSideCount;
            break;
          case 'amount':
            FieldDefinition = OrderBookSideAmount;
            break;
          case 'total':
            FieldDefinition = OrderBookSideTotal;
            break;
          case 'price':
            FieldDefinition = OrderBookSidePrice;
            break;
          default:
            return null;
        }

        return (
          <div key={field.key} className={field.key}>
            <FieldDefinition metric={params.metric} total={params.total} />
          </div>
        );
      })}
    </div>
  );
}

export default orderBookRow;
export const OrderBookRow = orderBookRow;
