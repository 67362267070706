import { BITFINEX_CONNECTED, BITFINEX_DISCONNECTED } from '../const';

const initialValue = {
  connected: false,
};

/**
 * Reducer for status. Take actual connection status and updates the state
 * @param state
 * @param action
 */
const statusReducer = (state: any = initialValue, action: any) => {
  switch (action.type) {
    case BITFINEX_CONNECTED:
    case BITFINEX_DISCONNECTED:
      return {
        ...state,
        connected: action.type === BITFINEX_CONNECTED ? true : false,
      };
    default:
      return state;
  }
};

export default statusReducer;
