export {};

export const enum SIDE {
  asks = 'asks',
  bids = 'bids',
}

export type Side = SIDE;

export const enum ORDER_BOOK_STATUS_MODES {
  REAL_TIME = 'REAL_TIME',
  THROTTLED5S = 'THROTTLED5S',
}
export type OrderBookStatusModes = ORDER_BOOK_STATUS_MODES;

export const ORDER_BOOK_PARAMETERS_FREQ = {
  F0: 'F0',
  F1: 'F1',
  F2: 'F2',
};

export type OrderBookParametersFreq = keyof typeof ORDER_BOOK_PARAMETERS_FREQ;

export const enum ORDER_BOOK_PARAMETERS_PREC {
  P0 = 'P0',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
}

export type OrderBookParametersPrec = ORDER_BOOK_PARAMETERS_PREC;

export const enum ORDER_BOOK_PARAMETER_SYMBOL {
  tBTCUSD = 'tBTCUSD',
}

export type OrderBookParametersSymbol = ORDER_BOOK_PARAMETER_SYMBOL;

export const enum ORDER_BOOK_PARAMETER_CHANNEL {
  book = 'book',
}

export type OrderBookParametersChannel = ORDER_BOOK_PARAMETER_CHANNEL;
