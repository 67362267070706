import React from 'react';
import { Provider } from 'react-redux';
import OrderBook from './views/OrderBook';
import store from './redux/store';

import './App.scss';
import './_variables.scss';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <OrderBook />
      </div>
    </Provider>
  );
}

export default App;
