import {
  BITFINEX_CONNECT,
  BITFINEX_CONNECTED,
  BITFINEX_DISCONNECT,
  BITFINEX_DISCONNECTED,
  CHANGE_DISPLAY_PARAMETER,
  CHANGE_PARAMETER,
  UPDATE_ORDER_BOOK,
} from './const';
import { OrderBookParameters, ReduxSagasAction } from '../types';

/**
 * Connects to the Bitfinex websocket (listener is in sagas)
 * @FIXME Remove the parameters?
 * @param parameters
 */
export const bitfinexConnect = (parameters: OrderBookParameters) => ({
  type: BITFINEX_CONNECT,
  parameters,
});

/**
 * Disconnects from the Bitfinex websocket (listener is in sagas)
 */
export const bitfinexDisconnect = () => ({ type: BITFINEX_DISCONNECT });
export const updateOrderBook = (data: ReduxSagasAction) => ({
  type: UPDATE_ORDER_BOOK,
  data: data.data,
});

/**
 * Action for the status of the connection (Connected)
 */
export const bitfinexConnected = () => ({ type: BITFINEX_CONNECTED });

/**
 * Action for the status of the connection (Disconnected)
 */
export const bitfinexDisconnected = () => ({ type: BITFINEX_DISCONNECTED });

/**
 * Action for changing the websocket parameters
 * @param parameter
 * @param value
 */
export const changeParameter = (
  parameter: string,
  value: number | string | null
) => ({
  type: CHANGE_PARAMETER,
  parameter,
  value,
});

/**
 * Action for changing the display parameters
 * @param parameter
 * @param value
 */
export const changeDisplayParameter = (
  parameter: string,
  value: number | string | null
) => ({
  type: CHANGE_DISPLAY_PARAMETER,
  parameter,
  value,
});
