
/**
 * Changes symbol to its user-friendly name
 * @param symbol
 */
export const symbolToName = (symbol: string) => {
  switch (symbol) {
    case 'tBTCUSD':
      return 'BTC/USD';
  }

  return symbol;
};
