import { ReduxState } from '../../types';
import { combineReducers } from 'redux';

import orderBook from './orderBook';
import parameters from './parameters';
import displayParameters from './displayParameters';
import status from './status';

const appReducer = combineReducers({
  orderBook,
  parameters,
  displayParameters,
  status,
});

export default (state: ReduxState, action: any) => appReducer(state, action);
