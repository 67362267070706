import React from 'react';
import { OrderBookBarsProps, ReduxState } from '../../types';
import './Bars.scss';
import { useSelector } from 'react-redux';

/**
 * Displays SVG containing bars for each book
 * @param params    OrderBookBarsProps
 */
function orderBookBars(params: OrderBookBarsProps) {
  const displayParameters = useSelector((state: ReduxState) => {
    return state.displayParameters;
  });

  return (
    <div className="bars">
      <div className={'svg-container'}>
        <svg
          style={{
            paddingRight: 100 - 100 * displayParameters.zoom + '%',
          }}
          width={100 * displayParameters.zoom + '%'}
          height={params.bars.length * 17}
        >
          {params.bars.map((bar, index) => {
            if (isFinite(bar.percent) === false) {
              return null;
            }
            return (
              /**
               * Using index in the key hides movement up/down of bars
               */
              <rect
                key={'index' + bar.book + index}
                x="0"
                y={index * 17}
                width="100%"
                transform={'scale(' + bar.percent + ' 1)'}
                height="17"
                fillOpacity="0.6"
              ></rect>
            );
          })}
        </svg>
      </div>
    </div>
  );
}

export default orderBookBars;
export const OrderBookBars = orderBookBars;
