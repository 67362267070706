import React from 'react';
import {
  Bar,
  HeaderField,
  OrderBookSideProps,
  ReduxState,
  SIDE,
} from '../../types';
import { useSelector } from 'react-redux';
import { OrderBookRow } from './Row';
import Big from 'big.js';
import './Side.scss';
import { OrderBookHeader } from './Header';
import { OrderBookBars } from './Bars';

const headerFields: Array<HeaderField> = [
  { key: 'placeholder', name: '' },
  { key: 'alert', name: '' },
  { key: 'count', name: 'Count' },
  { key: 'amount', name: 'Amount' },
  { key: 'total', name: 'Total' },
  { key: 'price', name: 'Price' },
];

/**
 * Displays side (aka table) for order book
 * @param params
 */
function orderBookSide(params: OrderBookSideProps) {
  const orderBook = useSelector(
    (state: ReduxState) => {
      return state.orderBook[params.side];
    },
    (a, b) => {
      return a.generation === b.generation;
    }
  );

  const connected = useSelector((state: ReduxState) => {
    return state.status.connected;
  });

  const parameters = useSelector((state: ReduxState) => {
    return state.parameters;
  });

  const used = orderBook && 'keys' in orderBook.hashMap && orderBook.hashMap;

  if (!used) {
    return null;
  }

  const totalSum: Big = orderBook.totalSum;
  let total = new Big(0);

  let usedKeys = used.keys();
  if (params.side === SIDE.asks) {
    usedKeys = Array.from(usedKeys).reverse();
  }

  const bars: Array<Bar> = [];
  const rows = usedKeys.map((book: number, index: number) => {
    /**
     * Hides last element that overflows the table
     */
    if (index + 1 > parameters.len) {
      return null;
    }
    const metric = used.val(book);
    if (typeof metric.amount === 'number' && !isNaN(metric.amount)) {
      total = total.plus(metric.amount);
    }

    bars.push({
      book: book + '',
      percent:
        params.side === SIDE.asks
          ? (total as any) / (totalSum as any)
          : 1 - (total as any) / (totalSum as any),
    });

    return (
      <OrderBookRow
        key={book + ''}
        index={book + ''}
        header={headerFields}
        side={params.side}
        total={total}
        totalSum={totalSum}
        metric={metric}
      >
        {book}
      </OrderBookRow>
    );
  });

  return (
    <div
      className={
        'side side-' +
        params.side +
        ' ' +
        (connected ? 'connected' : 'disconnected')
      }
    >
      <OrderBookBars side={params.side} bars={bars} />
      <div className={'header-rows'}>
        <OrderBookHeader header={headerFields} />
      </div>
      <div className={'rows-container'}>
        <div className={'rows'}>{rows}</div>
      </div>
    </div>
  );
}

export default orderBookSide;
export const OrderBookSide = orderBookSide;
