import React, { useEffect } from 'react';
import {
  OrderBookToolbar,
  OrderBookSide,
  OrderBookStatusBar,
} from '../components/OrderBook';
import { useDispatch, useSelector } from 'react-redux';
import { bitfinexConnect } from '../redux/actions';
import { OrderBookStatusOverlay } from '../components/OrderBook/StatusOverlay';
import { ReduxState, SIDE } from '../types';
import { useDocumentTitle } from '../utils/useDocumentTitle';

import './OrderBook.scss';

/**
 * OrderBook hook
 * @constructor
 */
function OrderBook() {
  const dispatch = useDispatch();

  const parameters = useSelector((state: ReduxState) => {
    return state.parameters;
  });

  useEffect(() => {
    dispatch(bitfinexConnect(parameters));
  }, [dispatch]);

  useDocumentTitle('Order Book');

  return (
    <div className="order-book">
      <OrderBookToolbar />
      <div className={'book-view'}>
        <OrderBookSide side={SIDE.asks} />
        <OrderBookSide side={SIDE.bids} />
        <OrderBookStatusOverlay />
      </div>
      <OrderBookStatusBar />
    </div>
  );
}

export default OrderBook;
