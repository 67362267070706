import { PriceProp, SortedHashMap } from '../types';

/**
 * Create a sorted hash map, which allows for O(log n) insertion and deletion.
 * Sorted access to the keys is established by keeping a sorted array of keys.
 */
function makeOrderedHash(): SortedHashMap {
  let keys: number[] = [];
  let vals: { [x: number]: PriceProp } = {};
  const hashObject = {
    push: function (k, v) {
      if (!vals[k]) {
        keys.push(k);
      }
      vals[k] = v;
    },
    insert: function (k, v) {
      const pos = binarySearchInsertPosition(keys, k); // O(log n)

      if (!vals[k]) {
        keys.splice(pos, 0, k);
      }
      vals[k] = v;

      return pos;
    },
    remove: function (k) {
      const pos = binarySearchInsertPosition(keys, k); // O(log n)
      keys.splice(pos, 1);
      delete vals[k];
    },
    val: function (k) {
      return vals[k];
    },
    length: function () {
      return keys.length;
    },
    keys: function () {
      return keys;
    },
    clear: function () {
      vals = {};
      keys = [];
    },
    values: function () {
      return vals;
    },
  } as SortedHashMap;
  return hashObject;
}

/**
 * Simple binary search  O(log n) to find position of the lower value
 * @param array
 * @param value
 * @returns position
 */
function binarySearchInsertPosition(array: number[], value: number): number {
  let start = 0;
  let end = array.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);

    if (array[mid] === value) {
      return mid;
    }
    if (array[mid] < value) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  return start;
}

export default makeOrderedHash;
