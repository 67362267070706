import { useEffect } from 'react';

/**
 * Hook that sets document title
 * @param title
 */
export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title} - Jędrzej Majko`;
  }, [title]);

  return null;
};
