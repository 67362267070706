import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bitfinexConnect } from '../../redux/actions';
import { ReduxState } from '../../types';
import './StatusOverlay.scss';

/**
 * Overlay, which is displayed when the connection is lost.
 * Allows reconnecting to the websocket.
 */
function orderBookStatusOverlay() {
  const dispatch = useDispatch();
  const status = useSelector((state: ReduxState) => {
    return state.status;
  });
  const parameters = useSelector((state: ReduxState) => {
    return state.parameters;
  });

  return !status.connected ? (
    <div className="status-overlay">
      <div
        onClick={() => {
          dispatch(bitfinexConnect(parameters));
        }}
      >
        {'Reconnect'}
      </div>
    </div>
  ) : null;
}

export default orderBookStatusOverlay;
export const OrderBookStatusOverlay = orderBookStatusOverlay;
