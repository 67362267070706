import {
  OrderBook,
  OrderBookGeneration,
  OrderBookParseRequest,
  PriceProp,
} from '../types';
import Big from 'big.js';

/**
 * Parse the response from the server and update the order book
 * @param params
 */
export const parseResponse = (params: OrderBookParseRequest) => {
  if (!params.orderBook) {
    return;
  }

  for (const dataset of params.newData) {
    insertElementsIntoOrderBook(dataset, params.orderBook);
  }

  /*const { orderBook, newData: msg } = params;
   */
  modifyPercentageUsage(params.orderBook.asks);
  modifyPercentageUsage(params.orderBook.bids);

  return params.orderBook;
};

/**
 * Classify and insert elements into orderBook
 * @param msg
 * @param orderBook
 */
export const insertElementsIntoOrderBook = (msg: any, orderBook: OrderBook) => {
  if (msg.event) {
    return;
  }
  if (msg[1] === 'hb') {
    return;
  }
  if (msg[1] === 'cs') {
    return;
  }
  if (msg[1].length === 3) {
    insertElement(orderBook, msg[1]);
  } else {
    flushOrderBook(orderBook);

    for (const line of msg[1]) {
      insertElement(orderBook, line);
    }
  }
};

/**
 * Clear the order book
 * @param orderBook
 */
export const flushOrderBook = (orderBook: OrderBook) => {
  orderBook.asks.hashMap.clear();
  orderBook.bids.hashMap.clear();
};

/**
 * Modifies total sum of all order rows for a modified book
 * @param orderBook
 */
const modifyPercentageUsage = (orderBook: OrderBookGeneration) => {
  if (orderBook.modified) {
    orderBook.modified = false;
    const used = orderBook.hashMap;

    const usedKeys = used.keys();
    let totalSum: Big = new Big(0);

    for (const key of usedKeys) {
      const metric = used.val(key);
      if (typeof metric.amount === 'number' && !isNaN(metric.amount)) {
        totalSum = totalSum.plus(metric.amount);
      }
    }
    orderBook.totalSum = totalSum;
  }
};

/**
 * Insert element into hash table or update it, setting a trend
 * @param orderBook
 * @param currentPriceLine
 */
const insertElement = (orderBook: OrderBook, currentPriceLine: any) => {
  const pp = {
    price: currentPriceLine[0],
    cnt: currentPriceLine[1],
    amount: currentPriceLine[2],
    percent: 0,
    trend: null,
    hash: 0,
  } as PriceProp;

  const side = pp.amount >= 0 ? 'asks' : 'bids';

  if (currentPriceLine[1] === 0) {
    // Remove this price line
    orderBook[side].hashMap.remove(pp.price);
    return;
  }

  pp.amount = Math.abs(pp.amount);
  const previousValue = orderBook[side].hashMap.val(pp.price);
  if (previousValue) {
    pp.trend = previousValue.cnt < pp.cnt;
  } else {
    pp.trend = null;
  }
  orderBook[side].hashMap.insert(pp.price, pp);

  orderBook[side] = {
    generation: (orderBook[side].generation % Number.MAX_SAFE_INTEGER) + 1,
    modified: true,
    totalSum: orderBook[side].totalSum,
    hashMap: orderBook[side].hashMap,
  };
};
